import axios, { InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import authApi from './authApi'
import Define from '../constants/define'
import {
  CLIENT_HEADER,
  COMPANY_HEADER,
  WAREHOUSE_HEADER,
  permissionsUrlPath,
} from './profile'
import { pick } from 'lodash'
import { store } from '../app/store'
import { showPermissionError } from '../utils/notification'
import { t } from 'i18next'
import { isPermissionEnabled } from '../utils/permissions'

const baseAxios = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    // timeout: 30000,
  },
})

// Add a request interceptor
baseAxios.interceptors.request.use(
  // @ts-ignore
  function (config) {
    // Do something before request is sent
    const cookieValue = Cookies.get('SSO_COOKIE')
    const permissionsHeaders = isPermissionEnabled()
      ? getPermissionsHeaders(config)
      : {}

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: config.headers?.Authorization || 'Bearer ' + cookieValue,
        ...permissionsHeaders,
      },
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

const getPermissionsHeaders = (config: InternalAxiosRequestConfig<any>) => {
  const { url, headers } = config
  let permissionsHeaders = {}
  if (url?.includes(permissionsUrlPath)) {
    permissionsHeaders = pick(headers, [
      CLIENT_HEADER,
      COMPANY_HEADER,
      WAREHOUSE_HEADER,
    ])
  } else {
    const { account } = store.getState()
    const client_id =
      account.choosingClient?.id ||
      localStorage.getItem(Define.CHOOSING_CLIENT) ||
      ''
    const company_id =
      account.choosingCompany ||
      localStorage.getItem(Define.CHOOSING_COMPANY) ||
      ''
    const warehouse_id =
      account.choosingWarehouse ||
      localStorage.getItem(Define.CHOOSING_WAREHOUSE) ||
      ''
    permissionsHeaders = {
      [CLIENT_HEADER]: client_id,
      [COMPANY_HEADER]: company_id,
      [WAREHOUSE_HEADER]: warehouse_id,
    }
  }
  return permissionsHeaders
}

const getMainDomain = () => {
  const hostname = window.location.hostname
  const parts = hostname.split('.')
  return parts.slice(-2).join('.')
}

export async function handleRefreshToken(error?: any) {
  if (!error?.config?.retried) {
    try {
      const { data } = await authApi.resetToken()
      const domain = getMainDomain()
      if (data?.data) {
        // removeCookies()
        const {
          access_token,
          refresh_token,
          access_token_exp_at,
          refresh_token_exp_at,
        } = data?.data || {}
        Cookies.set('SSO_COOKIE', access_token, {
          // @ts-ignore
          expires: new Date(Date.parse(access_token_exp_at)),
          domain,
        })
        Cookies.set('SSO_COOKIE-X', refresh_token, {
          // @ts-ignore
          expires: new Date(Date.parse(refresh_token_exp_at)),
          domain,
        })
        Cookies.set('ACCESS_TOKEN_EXP_AT', `${access_token_exp_at}`, { domain })
        Cookies.set('REFRESH_TOKEN_EXP_AT', `${refresh_token_exp_at}`, {
          domain,
        })

        // Retry the request
        if (error) {
          return baseAxios.request({
            ...error.config,
            retried: true,
            headers: {
              ...error.config.headers,
              Authorization: 'Bearer ' + access_token,
            },
          })
        }
        return
      }
    } catch (e) {
      const expiredAtString = Cookies.get('ACCESS_TOKEN_EXP_AT')
      if (expiredAtString) {
        const timestampExpireToken = Date.parse(expiredAtString)
        // If the token is going to expire in 1 minutes, then logout
        if (timestampExpireToken - Date.now() < 60 * 1000) {
          logout()
        } else {
          return baseAxios.request({
            ...error.config,
            retried: true,
            headers: {
              ...error.config.headers,
            },
          })
        }
      } else {
        logout()
      }
    }
  }
}

// Add a response interceptor
baseAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // if any api got 401 response status. Then try to refetch access token by refresh token
      // In case refetch token success then retry the request one more time
      // If refetch token failed then logout
      return await handleRefreshToken(error)
    }
    if (
      error.response?.code === 403 &&
      error.response.error === 'permission denied'
    ) {
      showPermissionError()
    }
    return Promise.reject(error)
  }
)

export default baseAxios

export function removeCookies() {
  Cookies.remove('SSO_COOKIE', {
    path: '/',
    domain: Define.DOMAIN,
  })
  Cookies.remove('SSO_COOKIE-X', {
    path: '/',
    domain: Define.DOMAIN,
  })
  Cookies.remove('ACCESS_TOKEN_EXP_AT', {
    path: '/',
    domain: Define.DOMAIN,
  })
  Cookies.remove('REFRESH_TOKEN_EXP_AT', {
    path: '/',
    domain: Define.DOMAIN,
  })
  Cookies.set('SSO_COOKIE', '', { expires: 0.0000001 })
  Cookies.set('SSO_COOKIE-X', '', { expires: 0.0000001 })
  Cookies.set('ACCESS_TOKEN_EXP_AT', '', { expires: 0.0000001 })
  Cookies.set('REFRESH_TOKEN_EXP_AT', '', { expires: 0.0000001 })
}

export async function logout() {
  const baseURL = process.env.REACT_APP_API_ENDPOINT?.replace('/api/v1', '')
  /**Call API logout */
  let body = {
    user_id: localStorage.getItem(Define.USER_ID),
    token: Cookies.get('SSO_COOKIE-X'),
  }
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT?.replace('/api/v1', '')}/signout`,
      body
    )
    .catch((_) => {
      throw new Error('Error')
    })
    .finally(() => {
      removeCookies()

      window.location.replace(`${baseURL}/?s_url=${window.location.href}`)
      /**Clear data on localStorage */
      localStorage.removeItem(Define.ACCESS_TOKEN)
      localStorage.removeItem(Define.CLIENT_ID)
      localStorage.removeItem(Define.ROLE)
      localStorage.removeItem(Define.USERNAME)
    })
}
